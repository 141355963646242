import React, {useState} from 'react';
import StopProcessComponent from '../../Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../Modals/StopConfirmModal/stopConfirmModal.component';

const Step3Component: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShow, setModalShow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };
  return (
    <>
     <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
        />
      <div className="col-12">
        <h3 className="h2 text-400">Participa en el taller informativo</h3>
        <div className="alert alert--simple-check col-md-8 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20"><strong>Tu participación dentro del taller es importante para continuar con el procedimiento.</strong></span>
        </div>
        <article>
          <ul className="text-20">
            <li className="mb-3">El taller es un encuentro-reunión, donde podrás aprender el paso a paso sobre como realizar este tipo de procedimientos de manera segura (con base en información científica).</li>
            <li>Habrá otras mujeres en la misma situación y entre todas se pueden apoyar.</li>
          </ul>
        </article>
      </div>
      <div className="col-lg-5 col-12">
        <div className="form-row module-top40 form--last">
          <a
            href="/talleres"
            className="btn btn--type1"
          >Registrarme al taller</a>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            onClick={() => setModalShow(true)}
          >Ya no necesito este acompañamiento</button>
        </div>
      </div>
    </>
  );
};

export default Step3Component;
