import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import ForgotPasswordComponent from '../../../components/Auth/ForgotPassword/forgotPassword.component';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';

const ForgotPasswordScreen = () => {
  const [cards, setCards] = useState<strapiDoc>();

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const query = {
          populate: {
            '0': 'cards',
            cards: {
              populate: '*',
            },
          },
        };
        const response = await fetchStrapiSingle('demographic-card', query);
        if (response) {
          setCards(response.data);
        }
      } catch (error) {
        console.error('Error fetching cards', error);
      }
    };
    fetchCards();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="col-md-5 col-12">
                <h1>¿Olvidaste tu contraseña?</h1>
                <ForgotPasswordComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="illus-row module-bottom row">
              {cards &&
                cards.attributes.cards &&
                cards.attributes.cards.map(
                  (
                    {
                      header,
                      content,
                      image: {
                        data: { attributes: image },
                      },
                    },
                    index
                  ) => (
                    <div
                      key={index}
                      className="illus-row__col col-lg-4 col-sm-6 col-12"
                    >
                      <div className="item-illus">
                        <figure>
                          <img
                            src={image.url}
                            alt={image.alternativeText || ''}
                          />
                        </figure>
                        <article>
                          <h2>{header}</h2>
                          <p>{content}</p>
                        </article>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ForgotPasswordScreen;
