import React, { useState, useEffect } from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import FooterShared from '../../../shared/Header/footer.shared';
import Step1Component from '../../../components/Process/Step1/step1.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import Swal from 'sweetalert2';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { IUser, UserStatus } from '../../../types/userInterface';
import LoaderComponent from '../../../components/Loader/loader.component';

const ProcessScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Recibimos tu información',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    console.log({ token });
    const fetchSession = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.amigasUser || {});
      } catch (error) {
        setError(`${error}`);
      } finally {
        setLoading(false);
      }
    };
    setTimeout(() => {
      fetchSession();
    }, 500);
  }, []);

  return user.status !== UserStatus.ACTIVE ? null : (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="mb-3 text-64">
                  Hola, {`${user.username || ''}`}
                </h1>
                <p className="mb-0 text-32">
                  <strong>No. {`${user.userId || ''}`}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={1} />
              </div>
              <Step1Component />
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ProcessScreen;
