import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface DeleteConfirmProps {
  show: boolean;
  onHide: () => void;
}

const DeleteConfirmComponent: React.FC<DeleteConfirmProps> = (props) => {



  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal--md"
    >
     
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-center">
        <figure className="image-490">
          <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
        </figure>
        </div>
       <p className="text-24">Tu cuenta fue cerrada y toda la información fue eliminada de la base de datos</p>
       <p className="text-24">Si esto es un error deberás registrarte nuevamente</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <a
            href="/"
            className="btn btn--type1"
          >Salir</a>
        </div>
        <div className="col-12 text-center mb-4">
          <a href="/crear-cuenta"
            className="btn--simple"
          >Volver a registrárme</a>
        </div>
       
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmComponent;
