import React, { useState } from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import ActiveCodeComponent from '../../../components/Auth/ActiveCode/activeCoce.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { IUser } from '../../../types/userInterface';

interface ActiveCodeScreenProps {
  user?: Partial<IUser>;
}

const ActiveCodeScreen: React.FC<ActiveCodeScreenProps> = ({ user }) => {
  const [currentStep] = useState(3);
  const totalSteps = 3;
  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
                <StepperComponent
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              </div>
              <div className="col-md-5 col-12">
                <h1>Código de activación</h1>
                <article className="text-20 col-12 col-reset">
                  <p>
                    Elige el medio por el cuál te enviaremos tu número de
                    activación y confirmación:
                  </p>
                </article>
                <ActiveCodeComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ActiveCodeScreen;
