import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { ITooltip } from '../../../types/strapiDocs';
import { IUser } from '../../../types/userInterface';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import Swal from 'sweetalert2';
import { updateUser } from '../../../api/usersApi';
import LoaderComponent from '../../Loader/loader.component';

interface Props {
  niveles: ITooltip[];
}

interface Answer {
  id: number;
  value: string;
}

const ViolentometroComponent = ({ niveles }: Props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const verifyFields = () => {
    setFormularioValido(answers.length > 0);
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = {
        ...user,
      };
      const userId = currentUser._id || '';

      currentUser.step = 10;
      const violentometro = currentUser.violentometro || {};
      violentometro.niveles = answers.sort((a, b) => a.id - b.id);
      currentUser.violentometro = violentometro;

      await updateUser(userId, currentUser, token);
      navigate('/incidencias');
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.amigasUser || {});
        const violentometro = currentUser?.amigasUser?.violentometro || {};
        const preguntas = violentometro.niveles || [];
        setAnswers(preguntas);
        verifyFields();
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    verifyFields();
  }, [answers]);

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block module-top40">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <section className="violent-section">
            <aside className="violent-figure">
              <div className="violent-level level1">
                <div className="violent-figure__item item--color1"></div>
                <div className="violent-figure__item item--color1"></div>
                <div className="violent-figure__item item--color1"></div>
                <div className="violent-figure__item item--color1"></div>
                <div className="violent-figure__item item--color1"></div>
                <p className="text-black">
                  <strong>Ten cuidado, la violencia aumentará</strong>
                </p>
              </div>
              <div className="violent-level">
                <div className="violent-figure__item item--color2"></div>
                <div className="violent-figure__item item--color2"></div>
                <div className="violent-figure__item item--color2"></div>
                <div className="violent-figure__item item--color2"></div>
                <div className="violent-figure__item item--color2"></div>
              </div>
              <div className="violent-level">
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <div className="violent-figure__item item--color3"></div>
                <p className="text-black">
                  <strong>No te dejes destruir, reacciona</strong>
                </p>
              </div>
              <div className="violent-level">
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <div className="violent-figure__item item--color4"></div>
                <p className="text-black">
                  <strong>Busca ayuda profesional</strong>
                </p>
              </div>
              <div className="violent-figure__end"></div>
            </aside>
            <div className="violent-content">
              {niveles
                .sort((a, b) => (a.order || 0) - (b.order || 0))
                .map((nivel, index) => (
                  <div className="violent-row row--center" key={index}>
                    <p className="violent-number">{nivel.order}</p>
                    <div className="form-check check--center">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        value={nivel.field}
                        name="violentometro"
                        id={`violentometro-${nivel.order}`}
                        checked={answers.some((a) => a.id === nivel.order)}
                        onChange={(e) => {
                          const currentAnswers = [...answers];
                          const index = currentAnswers.findIndex(
                            (a) => a.id === nivel.order
                          );
                          const checked = e.target.checked;
                          if (index > -1) {
                            if (!checked) {
                              currentAnswers.splice(index, 1);
                            }
                          } else {
                            currentAnswers.push({
                              id: nivel.order as number,
                              value: nivel.field,
                            });
                          }
                          setAnswers(currentAnswers);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`violentometro-${nivel.order}`}
                      >
                        <strong>{nivel.field}</strong>
                        {nivel.content && <small>{nivel.content}</small>}
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          </section>
          <div className="form-row module-top40 form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ViolentometroComponent;
