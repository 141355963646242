import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import LoaderComponent from '../../Loader/loader.component';

const ActiveCodeComponent: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeMethod, setActiveMethod] = useState<'email' | 'phone' | null>(
    null
  );
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const handleMethodClick = (method: 'email' | 'phone') => {
    setActiveMethod(method);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (activeMethod === 'email') {
      navigate('/codigo-email');
    } else if (activeMethod === 'phone') {
      navigate('/codigo-telefonico');
    }
  };

  const handleDelete = () => {
    setModalShow(false);
    setModalShowConfirm(true);
  };

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="active-choose">
              <button
                type="button"
                className={`btn btn--code ${
                  activeMethod === 'email' ? 'active' : ''
                }`}
                onClick={() => handleMethodClick('email')}
              >
                <i className="fa-regular fa-envelope"></i>
                <p className="text-24">Correo electrónico</p>
              </button>
              <button
                type="button"
                className={`btn btn--code ${
                  activeMethod === 'phone' ? 'active' : ''
                }`}
                onClick={() => handleMethodClick('phone')}
              >
                <i className="fa-brands fa-whatsapp"></i>
                <p className="text-24">Número telefónico</p>
              </button>
            </div>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!activeMethod}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ActiveCodeComponent;
