import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface TallerSelectionProps {
  show: boolean;
  onHide: () => void;
}

const TallerSelectionComponent: React.FC<TallerSelectionProps> = ({ show, onHide }) => {
 
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header  className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>Confirma tu asistencia al taller</h2>
          <p className="text-24">Revisa los datos para tu participación</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="modal-selection__row row">
            <div className="modal-selection__left col-lg-6 col-12">
              <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--success">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>ONLINE</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                  </article>
                </div>
            </div>
            <aside className="modal-selection__right col-lg-6 col-12 text-center">
              <h4 className="text-regular text-500 text-black">Siguientes pasos:</h4>
              <ul className="center-list">
                <li>
                  <i className="icon icon--conversation"></i>
                  <p>Te enviaremos todos los detalles <strong>al Chat Grupal</strong></p>
                </li>
                <li>
                  <i className="icon icon--love"></i>
                  <p>Si tienes dudas, <strong>contacta a tu acompañante</strong></p>
                </li>
              </ul>
            </aside>
          </div>
      </Modal.Body>
      <Modal.Footer>
      
        <div className="col-12 text-center mb-4">
          <a href="/chat-grupal"
              className="btn btn--type1"
            >
              Confirmar e ir al grupo de WhatsApp
            </a>
            
          </div>
          <div className="col-12 text-center mb-4">
            <button type="button" onClick={onHide} className="btn--type3">
              Elegir otro taller en otra fecha
            </button>
            
          </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TallerSelectionComponent;
