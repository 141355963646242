import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import FooterShared from '../../../shared/Header/footer.shared';
import Step3Component from '../../../components/Process/Step3/step3.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';

const TallerScreen: React.FC = () => {

  const steps = [
    { title: "Cuéntanos de ti", description: "Perfil completado", status: "status--success", completed: true, color: "green300" },
    { title: "Te presentamos a tu acompañante", description: "Completado", status: "status--success", completed: true, color: "green300" },
    { title: "Te invitamos al taller", description: "Revisa catálogo", status: "status--process", completed: false, color: "purple500" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];



  return (
   <>

   
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, Paulina</h1>
                <p className="text-32 mb-0"><strong>No. 3162432</strong></p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
              <Step3Component />
            </div>
           
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
   </>
  );
};

export default TallerScreen;
