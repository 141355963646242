import React from 'react';

const DonateComponent: React.FC = () => {
  return (
    <div className="banner-donate">
      <div className="banner-donate__article text-center">
        <article>
          <h2>Cada donativo hace la diferencia</h2>
          <p className="text-24">Nos ayuda a continuar ayudando a que más mujeres tengan acceso a un procedimiento seguro y confiable.</p>
        </article>
        <a href="/donativo" className="btn btn--type1 btn--270">Haz un donativo</a>
      </div>
      <figure className="banner-donate__image image-280">
        <img src="/assets/images/illustrations/safe-white.png" alt="Haz un donativo" />
      </figure>
    </div>
  );
};

export default DonateComponent;
