import React, {useState, useEffect} from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import atencionMedicaData from '../../../utils/atencion-medica.json'; 
import { useNavigate } from 'react-router-dom';

const Step5Component: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [atencionMedica, setAtencionMedica] = useState(atencionMedicaData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formularioValido, setFormularioValido] = useState(false);
  const [atencionMedicaShow, setAtencionMedicaShow] = useState(false);

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        // Verificar si al menos uno del grupo está seleccionado
        const grupoSeleccionado = !!document.querySelector(`input[name="${campo.name}"]:checked`);
        todosCompletos = todosCompletos && grupoSeleccionado;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
  
    setFormularioValido(todosCompletos);
  }
  
    

  useEffect(() => {
    verifyFields();
  }, []);

  const handleAtencionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mostrar = e.target.value === 'si';
    setAtencionMedicaShow(mostrar);
    if (mostrar) {
      verifyFields();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEmocionesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    verifyFields();
  };

  const handleSubmit = () => {
    navigate('/procedimiento-completo');
  };

  return (
    <>
   
      <div className="col-12 text-center">
        <h3 className="text-52 text-500 mb-3">Seguimiento post procedimiento</h3>
        <p className="text-24">Queremos saber cómo te sientes</p>
      </div>
      <div className="row">
        <div className="col-md-5 col-12">
          <article className="text-20">
            <h2 className="text-green900 text-regular mb-3">¡Hola!<br /> Han pasado dos semanas desde tu procedimiento.</h2>
            <p>Nos gustaría saber cómo has estado en estos días.</p>
            <p>Tu historia y experiencia son invaluables para comprender mejor los procesos relacionados con la salud ginecológica de las mujeres y gracias a esa información, seguir mejorando en nuestro trabajo diario.</p>
          </article>
          <div className="form-block">
            <form onSubmit={handleSubmit}>

              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Cuántos días estuviste sangrando después de la expulsión?
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip >
                        ¿Cuántos días estuviste sangrando después de la expulsión?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
                </label>
                <input type="text" className="form-control" placeholder="" />
              </div>
              
              <div className="form-row">
                <label className="form-label label--icon">
                  ¿Tuviste necesidad de atención médica? 
                </label>
                <div className="form-check-multi">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="atencion" id="atencionSi" value="si" onChange={handleAtencionChange}/>
                    <label className="form-check-label" htmlFor="atencionSi">
                      Sí
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="atencion" id="atencionNo" value="no" onChange={handleAtencionChange}/>
                    <label className="form-check-label" htmlFor="atencionNo">
                      No
                    </label>
                  </div>
                 
                </div>
              </div>
              {atencionMedicaShow && (
               <>
                 <div className="form-row">
                  <label className="form-label label--icon">
                    Registra la fecha de la atención médica
                  </label>
                  <input type="date" className="form-control" placeholder="Selecciona la fecha aquí" />
                </div>

                <div className="form-row">
                  <label className="form-label label--icon">
                    ¿Qué tipo de atención médica recibiste?
                  </label>
                  <select
                    className="form-select"
                    aria-label="¿Qué tipo de atención médica recibiste?"
                    defaultValue={'default'}
                    name="atencionMedica"
                  >
                    <option value="default" disabled>Elige una de las opciones</option>
                    {atencionMedica.map((atencion) => (
                      <option key={atencion.id} value={atencion.id}>{atencion.label}</option>
                    ))}
                  </select>
                </div>
               </>
              )}
              
              <div className="form-row">
                <Alert variant='success' className="alert--soft-success">
                  <i className="icon fa-regular fa-flag"></i>
                  <article>
                    <p className="mb-3">Recuerda que en Chile existen las oficinas de reclamos y sugerencias, y están disponibles de manera física y en línea.</p>
                    <p>Si sientes que la atención que te brindaron no fue suficiente, no se respetaron tus derechos o tu integridad, puedes <strong>iniciar un reclamo dando click aquí abajo</strong>. </p>
                    <div className="col-12 col-reset d-flex justify-content-center mt-4">
                      <a href="https://oirs.minsal.cl/atencionusuario.aspx" target='_blank' rel="noreferrer" className="btn btn--type1 text-white">Iniciar un reclamo OIRS</a>
                    </div>
                    <div className="col-12 col-reset d-flex justify-content-center mt-4">
                      <a href="#" target='_blank' className="btn--simple text-purple500">Más información sobre OIRS</a>
                    </div>
                  </article>
                </Alert>
              </div>
              <div className="form-row">
                <label className="form-label">¿Quieres compartir con nosotras alguna de las emociones que sentiste posteriormente al procedimiento? <small className="required">*</small></label>
                <textarea
                  className="form-control requiredField"
                  placeholder="Ingresa aquí tus emociones y sensaciones"
                  id="emociones"
                  onChange={handleEmocionesChange}
                ></textarea>
              </div>
              <div className="form-row  form--last">
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={!formularioValido}
                >Guardar y continuar</button>
              </div>
            </form>
          </div>
        </div>
        <aside className="auto-row__right col-md-7 col-12">
          <figure className="image-400">
            <img src="/assets/images/illustrations/women.png" alt="Women" />
          </figure>
        </aside>
      </div>
    </>
  );
};

export default Step5Component;
