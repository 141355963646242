import React, { useState } from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import PhoneComponent from '../../../components/Auth/Phone/phone.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { IUser } from '../../../types/userInterface';

interface PhoneScreenProps {
  user?: Partial<IUser>;
}

const PhoneScreen: React.FC<PhoneScreenProps> = ({ user }) => {
  const [currentStep] = useState(2);
  const totalSteps = 3;
  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
                <StepperComponent
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              </div>
              <div className="col-md-5 col-12">
                <h1>Información de contacto</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green">
                    Quédate tranquila, tus datos siempre están protegidos.
                  </p>
                </article>
                <PhoneComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default PhoneScreen;
