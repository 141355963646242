import React, {useState} from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../../shared/Header/footer.shared';
import TallerSelectionComponent from '../../../../components/Modals/TallerModal/tallerModal.component';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';

const TalleresScreen: React.FC = () => {
  const [modalShow, setModalShow] = useState(false);
  const steps = [
    { title: "Cuéntanos de ti", description: "Perfil completado", status: "status--success", completed: true, color: "green300" },
    { title: "Te presentamos a tu acompañante", description: "Completado", status: "status--success", completed: true, color: "green300" },
    { title: "Te invitamos al taller", description: "Revisa catálogo", status: "status--process", completed: false, color: "purple500" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];



  return (
   <>
      <TallerSelectionComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
   
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
           
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
              
            </div>
           <div className="module-bottom row">
            <div className="col-12 text-center">
              <h3 className="h1">Taller</h3>
              <p className="text-20 module-bottom40">Hola soy tu tallerista.</p>
              <p>Regístrate en uno de los talleres disponibles, recuerda que es únicamente para mujeres. </p>
            </div>
           </div>

            <div className="row">
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--success">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>ONLINE</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--success">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>ONLINE</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--primary">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>TELEFÓNICO</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--empty"></p>
                  
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--success">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>ONLINE</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--blue">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--success">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>ONLINE</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                  <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>PRESENCIAL</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                    <p className="badge badge-label badge--success">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>ONLINE</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
              <div className="item-workshop__col col-xl-3 col-lg-4 col-sm-6 col-12">
                <div className="item-workshop">
                  <figure className="item-workshop__image">
                  <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">verified</span>
                      <span><small>PRESENCIAL</small></span>
                    </p>
                    <img src="/assets/images/illustrations/remember.png" alt="Taller" />
                  </figure>
                  <article>
                    <p className="badge badge-label badge--info">
                      <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      <span>50 CUPOS DISPONIBLES</span>
                    </p>
                    <ul className="stock-list">
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined stock--enabled">airline_seat_recline_normal</span>
                      </li>
                      <li>
                        <span className="icon material-icons-outlined">airline_seat_recline_normal</span>
                      </li>
                      <li>50 CUPOS</li>
                    </ul>
                    <p className="text-gray600 mb-0">Sábado 30 de marzo, 2023</p>
                    <p className="text-icon"><i className="icon icon--clock"></i> 17:00 hrs</p>
                    <button type="button" onClick={() => setModalShow(true)} className="btn btn--type1">Regístrate</button>
                  </article>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <FooterShared />
   </>
  );
};

export default TalleresScreen;
