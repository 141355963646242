import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface StopConfirmProps {
  show: boolean;
  onHide: () => void;
}

const StopConfirmComponent: React.FC<StopConfirmProps> = (props) => {



  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal--md"
    >
     
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-center">
        <figure className="image-490">
          <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
        </figure>
        </div>
       <p className="text-24">Tu proceso fue detenido</p>
       <p className="text-24">Si esto es un error deberás empezar el proceso nuevamente</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <a
            href="/mi-proceso"
            className="btn btn--type1"
          >Aceptar</a>
        </div>
        
       
      </Modal.Footer>
    </Modal>
  );
};

export default StopConfirmComponent;
