import React, {useState} from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import { useNavigate } from 'react-router-dom';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';


const DonateScreen: React.FC = () => {
  const navigate = useNavigate();
  const [activeMethod, setActiveMethod] = useState<'deposito' | 'card' | 'paypal' | null>(null);

  const handleMethodClick = (method: 'deposito' | 'card' | 'paypal') => {
    setActiveMethod(method);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (activeMethod === 'deposito') {
      navigate('/donativo-deposito');
    } else if (activeMethod === 'card') {
      navigate('/donativo-tarjeta');
    } else if (activeMethod === 'paypal') {
      navigate('/donativo-gracias');
    }
  };

  return (
   <>
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-lg-6 col-12 mx-auto text-center">
                <h1 className="text-40 mb-3">Cada donativo hace la diferencia</h1>
                <p className="text-24">Nos ayuda a continuar ayudando a que más mujeres
tengan acceso a un procedimiento seguro y confiable.</p>
                <p>Selecciona la plataforma sobre la cual quieres realizar tu donativo:</p>
              </div>
            </div>
            <div className="module-bottom row">
              <form onSubmit={handleSubmit}>
                <section className="box-donate-grid">
                  <div className="box-donate">
                    <button
                      type="button"
                      className={`${activeMethod === 'deposito' ? 'active' : ''}`}
                      onClick={() => handleMethodClick('deposito')}
                    >
                      <p className="text-32 text-titular ">Depósito en tienda</p>
                      <div className="box-donate__icon">
                        <i className="icon icon--money"></i>
                      </div>
                    </button>
                  </div>
                  <div className="box-donate">
                    <button
                      type="button"
                      className={`${activeMethod === 'card' ? 'active' : ''}`}
                      onClick={() => handleMethodClick('card')}
                    >
                      <p className="text-32 text-titular">Pago con tarjeta</p>
                      <div className="box-donate__icon">
                        <i className="icon icon--amex"></i>
                        <i className="icon icon--mastercard"></i>
                        <i className="icon icon--visa"></i>
                      </div>
                    </button>
                  </div>
                  <div className="box-donate">
                    <button
                      type="button"
                      className={`${activeMethod === 'paypal' ? 'active' : ''}`}
                      onClick={() => handleMethodClick('paypal')}
                    >
                      <p className="text-32 text-titular">A través de PayPal</p>
                      <div className="box-donate__icon">
                        <i className="icon icon--paypal"></i>
                      </div>
                    </button>
                  </div>
                </section>
                <div className="form-row module-top40 form--last">
                  <button
                  type="submit"
                  className="btn btn--type1 btn--270"
                  disabled={!activeMethod}
                >Continuar</button>

                </div>
              </form>
            </div>
           
          </div>
        </div>
      </section>
      <FooterShared />
   </>
  );
};

export default DonateScreen;
