import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../../shared/Header/footer.shared';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import ChatComponent from '../../../../components/Chat/chat.component';
import { IUser } from '../../../../types/userInterface';

interface ChatGrupalScreenProps {
  user: Partial<IUser>;
}

const ChatGrupalScreen: React.FC<ChatGrupalScreenProps> = ({ user }) => {
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

  return (
    <>
      <HeaderShared />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>

            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={1} />
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <ChatComponent
                  user={user}
                  roomId="123"
                  token="token123"
                  messages={[]}
                />
              </div>
            </div>
            <div className="module-bottom-40 row">
              <div className="text-center col-12">
                <a href="/entrega" className="btn btn--type1">
                  Ir a mi entrega
                </a>
                <article className="mx-auto mt-4 col-lg-5 col-12">
                  <p>
                    Siéntete en confianza. Recuerda que tu historial de
                    conversación es privado, sólo Las Amigas y tú sabemos lo que
                    se está compartiendo.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ChatGrupalScreen;
