import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Swal from 'sweetalert2';
import Markdown from 'react-markdown';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import SocioCulturalComponent from '../../../components/Auth/SocioCultural/socioCultural.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import { IUser } from '../../../types/userInterface';

interface SocioCulturalScreenProps {
  user: Partial<IUser>;
}

const SocioCulturalScreen: React.FC<SocioCulturalScreenProps> = ({ user }) => {
  const [content, setContent] = useState<strapiDoc>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('contexto-sociocultural', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="col-md-5 col-12">
                <h1>{`${content?.attributes.header || ''}`}</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green">
                    {`${content?.attributes.subheader || ''}`}
                  </p>
                  <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
                </article>
                <Alert
                  variant="success"
                  className="alert--soft-success alert--complete"
                >
                  <Markdown
                    components={{
                      p: (props: React.DOMAttributes<HTMLParagraphElement>) => (
                        <p className="mb-3 text-base">{props.children || ''}</p>
                      ),
                    }}
                  >{`${content?.attributes.alert || ''}`}</Markdown>
                  <div className="col-12">
                    <i className="icon fa-regular fa-heart"></i>
                    <article>
                      <Markdown>
                        {content?.attributes.alertheart || ''}
                      </Markdown>
                    </article>
                  </div>
                </Alert>
                <SocioCulturalComponent
                  tooltips={content?.attributes.Tooltip || []}
                />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SocioCulturalScreen;
