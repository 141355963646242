import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';


const DonateThanksDepositScreen: React.FC = () => {

  return (
   <>
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-lg-6 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-490">
                    <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">¡ Gracias por tu donación !</h1>
                <p className="text-24">Liquorice dragée apple pie tootsie roll ice cream cake candy jelly beans. Pie marshmallow pie sesame snaps jelly. Dragée tootsie roll liquorice chocolate muffin pastry muffin jelly beans. </p>
                <p>Gracias por tu contribución, con tu donativo podemos seguir creciendo y ayudando a mas amigas.</p>
              </div>
            </div>
            <div className="form-row  form--last">
                  <a href="/"
                  className="btn btn--type1 btn--270"
                >Ir al Inicio</a>

                </div>
           
          </div>
        </div>
      </section>
      <FooterShared />
   </>
  );
};

export default DonateThanksDepositScreen;

