import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Markdown from 'react-markdown';
import { fetchStrapiSingle } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import LoaderComponent from '../../Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { updateUser } from '../../../api/usersApi';
import { IUser, UserStatus } from '../../../types/userInterface';
import { signOut } from 'aws-amplify/auth';

interface StopProcessProps {
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
}

const StopProcessComponent: React.FC<StopProcessProps> = ({
  show,
  onHide,
  onDelete,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<strapiDoc>();
  const [user, setUser] = useState<Partial<IUser>>({});

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked =
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0;
    setIsCheckboxChecked(isChecked);

    const boxIsChecked = event.target.checked;
    if (boxIsChecked) {
      const value = event.target.value;
      const reasons = user.cancelReason?.reasons || [];
      reasons.push(value);
      setUser({
        ...user,
        cancelReason: {
          reasons,
        },
      });
    } else {
      const value = event.target.value;
      const reasons = user.cancelReason?.reasons || [];
      const filteredReasons = reasons.filter((reason) => reason !== value);
      setUser({
        ...user,
        cancelReason: {
          reasons: filteredReasons,
        },
      });
    }
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      if (currentUser) {
        const { amigasUser, jwtToken } = currentUser;
        const userId = amigasUser._id || '';
        await updateUser(
          userId,
          {
            ...user,
            step: 0,
            username: '',
            phone: '',
            email: '',
            status: UserStatus.INACTIVE,
          },
          jwtToken
        );
        await signOut();
      }
      onDelete();
      onHide();
    } catch (error) {
      setError('Código incorrecto');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('detener-proceso', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>{`${content?.attributes.header || ''}`}</h2>
          <p className="text-24">{`${content?.attributes.subheader || ''}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {loading && <LoaderComponent />}
          <div className="form-row">
            <label className="form-label label--icon">
              <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
            </label>
            <div className="row-check-2 pt-4 row">
              {content?.attributes?.questions?.map((question, index) => (
                <div key={index} className="row-check-2__col col-6">
                  <div className="form-check">
                    <input
                      className={`form-check-input checkbox--green ${
                        question.requiredField ? 'requiredField' : ''
                      }`}
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      name="metodoComprobacion"
                      id={`${question.id}`}
                      value={question.Nombre}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`${question.id}`}
                    >
                      {question.Nombre}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon text-gray800">{`${
              content?.attributes?.observaciones || ''
            }`}</label>
            <textarea
              className="form-control form--green"
              id="other"
              rows={3}
              placeholder="Ingresa motivos adicionales o situaciones que puedan ayudar a Las Amigas a entender el porqué de esta decisión."
              onChange={(event) => {
                setUser({
                  ...user,
                  cancelReason: {
                    ...user.cancelReason,
                    observations: event.target.value,
                  },
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={!isCheckboxChecked}
            className="btn btn--type1 btn--290"
          >
            Detener el proceso
          </button>
        </div>
        <div className="col-12 text-center mb-4">
          <button
            type="button"
            onClick={onHide}
            className="btn btn--type2 btn--290"
          >
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StopProcessComponent;
