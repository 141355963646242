import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { updateUser } from '../../../api/usersApi';
import { UserStatus } from '../../../types/userInterface';
import { signOut } from 'aws-amplify/auth';

interface DeleteAccountProps {
  show: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onHide: () => void;
  onDelete: () => void;
}

const DeleteAccountComponent: React.FC<DeleteAccountProps> = ({
  show,
  onHide,
  onDelete,
  setLoading,
}) => {
  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      const user = await getCurrentAuthenticatedUser();
      if (!user) {
        throw new Error('No se encontró el usuario');
      }

      const token = user.jwtToken || '';
      const amigasUser = user.amigasUser || {};
      const userId = amigasUser._id || '';

      amigasUser.username = '';
      amigasUser.phone = '';
      amigasUser.email = '';

      await updateUser(
        userId,
        { ...amigasUser, step: 0, status: UserStatus.INACTIVE },
        token
      );
      await signOut();

      onDelete();
      onHide();
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>Estás a punto de borrar toda la información</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          Una vez que confirmes, toda la información desaparecerá de la base de
          datos.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="mb-4 text-center col-12">
          <button
            type="button"
            onClick={(event) => {
              handleSubmit(event);
            }}
            className="btn btn--delete"
          >
            Eliminar toda la información
          </button>
        </div>
        <div className="mb-4 text-center col-12">
          <button type="button" onClick={onHide} className="btn--simple">
            Regresar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountComponent;
