import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import DonateComponent from '../../../../components/Banners/Donate/donate.component';
import StepperComponent from '../../../../components/Stepper/stepper.component';
import CodePhoneComponent from '../../../../components/Auth/ActiveCode/codePhone/codePhone.component';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import {
  FetchUserAttributesOutput,
  fetchUserAttributes,
} from 'aws-amplify/auth';

const PhoneCodeScreen = () => {
  const [currentStep] = useState(1);
  const [userAttributes, setUserAttributes] =
    useState<FetchUserAttributesOutput>({});
  const totalSteps = 6;

  useEffect(() => {
    const fetchData = async () => {
      const userAttributes = await fetchUserAttributes();
      setUserAttributes(userAttributes);
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
                <StepperComponent
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              </div>
              <div className="col-md-5 col-12">
                <h1>Código de activación</h1>
                <article className="text-20 col-12 col-reset module-top40 ">
                  <p className="text-icon">
                    <i className="fa-brands fa-whatsapp"></i> Te acabamos de
                    enviar el código por SMS a tu número con terminación:
                  </p>
                  <p className="text-24">
                    <strong>
                      <span className="text-hide">
                        <i className="fa-solid fa-circle"></i>
                        <i className="fa-solid fa-circle"></i>
                        <i className="fa-solid fa-circle"></i>
                        <i className="fa-solid fa-circle"></i>
                      </span>
                      {userAttributes.phone_number?.slice(-4) || ''}
                    </strong>
                  </p>
                </article>
                <CodePhoneComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default PhoneCodeScreen;
