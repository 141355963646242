import React from 'react';
import { Alert } from 'react-bootstrap';

const PrivacyComponent: React.FC = () => {
  return (
    <Alert variant="success">
      <span className="material-icons-outlined">done</span>
      <article className="pl-0">
        <ul>
          <li>
            Recuerda que todos tus{' '}
            <strong>datos están protegidos y son confidenciales</strong>.
          </li>
          <li>
            Puedes <strong>borrar tu información</strong> en cuanto lo decidas.
          </li>
          <li>
            Consulta nuestra{' '}
            <a href="/politica-de-privacidad">Política de Privacidad</a>
          </li>
          <li>
            Te recordamos que este proceso y todo lo que implica{' '}
            <strong>es personal y no se puede divulgar</strong> de ninguna
            manera.
          </li>
        </ul>
      </article>
    </Alert>
  );
};

export default PrivacyComponent;
