import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Markdown from 'react-markdown';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import SexualidadEmocionesComponent from '../../../components/Auth/SexualidadEmociones/sexualidadEmociones.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import LoaderComponent from '../../../components/Loader/loader.component';
import { IUser } from '../../../types/userInterface';

interface Props {
  user: Partial<IUser>;
}

const SexualidadEmocionesScreen: React.FC<Props> = ({ user }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<strapiDoc>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('sexualidad-y-emocion', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="col-md-5 col-12">
                <h1>{`${content?.attributes.header || ''}`}</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green">
                    {`${content?.attributes.subheader || ''}`}
                  </p>
                  <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
                </article>
                {content?.attributes.questions?.length && (
                  <SexualidadEmocionesComponent
                    questions={content?.attributes.questions || []}
                    confianzaList={content?.attributes.confianzaList || []}
                  />
                )}
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SexualidadEmocionesScreen;
