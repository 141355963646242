import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Donate/donate.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';


const DonateCardScreen: React.FC = () => {
  

  return (
   <>
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <a href="/donativo" className="btn--icon btn--simple btn--simple--small"><i className="icon fa-solid fa-arrow-left"></i> Regresar</a>
              </div>
              <div className="col-lg-6 col-12 mx-auto module-top40">
                <h1 className="mb-3 text-center">Donación con tarjeta</h1>
                <div className="module-top40">
                  <DonateComponent />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
   </>
  );
};

export default DonateCardScreen;
