import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CheckerBannerComponent from '../../Form/CheckerBanner/checkerBanner.component';
import Swal from 'sweetalert2';
import { fetchStrapiSingle } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import Markdown from 'react-markdown';

interface CausalModalProps {
  show: boolean;
  onHide: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleModalSubmit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    signUpReason: string
  ) => Promise<void>;
}

const CausalModalComponent: React.FC<CausalModalProps> = ({
  show,
  onHide,
  setLoading,
  handleModalSubmit,
}) => {
  const [cards, setCards] = useState<strapiDoc>();
  const [causalData, setCausalData] = useState({
    number: '',
    text: '',
    isChecked: false,
  });

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleCausalChange = (
    id: number,
    number: string | undefined,
    text: string,
    isChecked: boolean
  ) => {
    const actualNumber = number || ''; // Usar cadena vacía si number es undefined
    setCausalData({ number: actualNumber, text, isChecked });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(causalData);
        setLoading(true);
        const query = {
          populate: {
            '0': 'card',
            card: {
              populate: '*',
            },
          },
        };
        const response = await fetchStrapiSingle('causales-modal', query);
        if (response) {
          setCards(response.data);
        }
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>¿En qué consiste el acompañamiento de OLA?</h2>
          <p className="text-24 ">
            Somos Observadoras de la Ley de Aborto y te podemos acompañar en tu
            proceso de acceso a la ley 21.030 IVE (Interrupción Voluntaria del
            Embarazo)
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-card__row row">
          {(cards?.attributes?.card || []).map(
            ({ header, content, ctaText, ctaLink, listado }, index) => (
              <div key={index} className="modal-card__col col-xl-4 col-12">
                <div className="card">
                  <div className="text-center card-body">
                    <h3 className="text-regular text-24 card-title">
                      {header || ''}
                    </h3>
                    <Markdown
                      className="card-text"
                      components={{
                        a: ({ ...props }) => (
                          <a className="text-green500">{props.children}</a>
                        ),
                      }}
                    >
                      {content || ''}
                    </Markdown>
                  </div>
                  {ctaText && ctaLink && (
                    <a
                      href={ctaLink}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn--type1 btn--icon btn--p20"
                    >
                      {ctaLink.includes('.pdf') ? (
                        <span className="material-icons-outlined icon">
                          file_download
                        </span>
                      ) : null}{' '}
                      {ctaText}
                    </a>
                  )}
                  {listado && (
                    <div className="checkers">
                      {listado.map(({ id, Nombre }) => (
                        <CheckerBannerComponent
                          key={id}
                          id={id}
                          onChange={handleCausalChange}
                          text={<span>{Nombre}</span>}
                          value={Nombre}
                          checked={causalData.text === Nombre}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mb-4 text-center col-12">
          <button
            onClick={(e) => handleModalSubmit(e, causalData.text)}
            className="btn btn--type1"
            disabled={!causalData.isChecked}
          >
            Continuar mi registro con Las Amigas
          </button>
        </div>
        <div className="mb-4 text-center col-12">
          <a href="https://olachile.org/" className="btn--simple">
            Continuar mi registro con OLA
          </a>
        </div>
        <div className="mb-4 text-center col-12">
          <button type="button" onClick={onHide} className="btn--danger">
            No estoy segura de querer continuar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CausalModalComponent;
