import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Markdown from 'react-markdown';
import Alert from 'react-bootstrap/Alert';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import SignUpComponent from '../../../components/Auth/SignUp/signUp.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import LoaderComponent from '../../../components/Loader/loader.component';

const SignUpScreen = () => {
  const [currentStep] = useState(1);
  const totalSteps = 3;
  const [loading, setLoading] = useState<boolean>(true);
  const [content, setContent] = useState<strapiDoc>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('vamos-a-crear-tu-cuenta', {
          populate: '*',
        });
        setContent(response.data);
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="auth-row module-bottom row">
              <div className="module-bottom40 col-12">
                <StepperComponent
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              </div>
              <div className="col-md-5 col-12">
                <h1>{`${content?.attributes.header || ''}`}</h1>
                <article className="text-20 col-12 col-reset">
                  <p className="text-green">
                    {`${content?.attributes.subheader || ''}`}
                  </p>
                  <Markdown>{`${content?.attributes.content || ''}`}</Markdown>
                </article>
                <Alert variant="success" className="alert--soft-success">
                  <i className="icon fa-regular fa-flag"></i>
                  <article>
                    <Markdown>{`${content?.attributes.alert || ''}`}</Markdown>
                  </article>
                </Alert>
                <SignUpComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SignUpScreen;
