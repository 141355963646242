import React, {useState} from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';


const DonateThanksScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userLogged, setUserLogged] = useState(false);
  return (
   <>
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>


            {userLogged ?
              <>
                <div className="module-bottom row">
                  <div className="col-lg-6 col-12 mx-auto text-center">
                    <div className="d-flex justify-content-center">
                      <figure className="image-490">
                        <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
                      </figure>
                    </div>
                    <h1 className="text-40 mb-3">¡ Gracias por tu donación !</h1>
                    <p>Gracias por tu contribución, con tu donativo podemos seguir creciendo y ayudando a mas amigas.</p>

                    
                  </div>
                </div>
                <div className="form-row  form--last">
                  <a href="/"
                  className="btn btn--type1 btn--270"
                >Ir al Inicio</a>

                </div>
              </>

             : 

              <>
                 <div className="module-bottom40 row">
                  <div className="col-lg-6 col-12 mx-auto text-center">
                    <div className="d-flex justify-content-center">
                      <figure className="image-490">
                        <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
                      </figure>
                    </div>
                    <h1 className="text-40 mb-3">¡ Gracias por tu donación !</h1>
                    <p>Con tu donativo podemos seguir creciendo y ayudando a más mujeres.</p>
                    <p className="text-green550"><strong>Recuerda que puedes seguir hablando con tu acompañantes dentro de los siguientes 30 días naturales</strong></p>

                    
                  </div>
                </div>
                <div className="form-row  form--last last--button">
                  <a href="/chat-acompanante"
                    className="btn btn--type2 "
                  >Hablar con mi acompañante</a>
                    <a href="/"
                    className="btn btn--type1"
                  >Regresar al inicio</a>

                </div>
              </>
            }
            
            

           
           
          </div>
        </div>
      </section>
     
      <FooterShared />
   </>
  );
};

export default DonateThanksScreen;

