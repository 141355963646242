import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface StopProcessProps {
  show: boolean;
  onHide: () => void;
}

const DonateModalComponent: React.FC<StopProcessProps> = ({ show, onHide }) => {
 
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header  className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>¿Nos ayudas con un donativo?</h2>
          <p className="text-24">Tu donativo nos ayuda a continuar ayudando a que más mujeres tengan acceso a un aborto seguro y confiable.</p>
          <p className="text-16">Podrás hacerlo a través de las siguientes plataformas:</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <section className="box-donate-grid">
        <div className="box-donate">
          <a href="/donativo-deposito"
          >
            <p className="text-32 text-titular ">Depósito en tienda</p>
            <div className="box-donate__icon">
              <i className="icon icon--money"></i>
            </div>
          </a>
        </div>
        <div className="box-donate">
        <a href="/donativo-tarjeta"
          >
            <p className="text-32 text-titular">Pago con tarjeta</p>
            <div className="box-donate__icon">
              <i className="icon icon--amex"></i>
              <i className="icon icon--mastercard"></i>
              <i className="icon icon--visa"></i>
            </div>
          </a>
        </div>
        <div className="box-donate">
        <a href="/donativo-gracias"
          >
            <p className="text-32 text-titular">A través de PayPal</p>
            <div className="box-donate__icon">
              <i className="icon icon--paypal"></i>
            </div>
          </a>
        </div>
      </section>
      </Modal.Body>
      <Modal.Footer>
      
        <div className="col-12 text-center mb-4">
          <a href="/opinion-completada"
             
              className="btn btn--type1"
            >
             No quiero hacer mi donativo por ahora
            </a>
            
          </div>
          
      </Modal.Footer>
    </Modal>
  );
};

export default DonateModalComponent;
