import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../../shared/Header/footer.shared';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';

const FinalizarOpinionScreen: React.FC = () => {

  const steps = [
    { title: "Cuéntanos de ti", description: "Perfil completado", status: "status--success", completed: true, color: "green300" },
    { title: "Te presentamos a tu acompañante", description: "Completado", status: "status--success", completed: true, color: "green300" },
    { title: "Te invitamos al taller", description: "Revisa catálogo", status: "status--success", completed: true, color: "green300" },
    { title: "Seguimos en contacto", description: "Completado", status: "status--success", completed: true, color: "green300" },
    { title: "Déjanos tu opinión", description: "Completdao", status: "status--success", completed: true, color: "green300" },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];



  return (
   <>

   
     <HeaderShared />
     <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
          
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
              
            </div>
            <div className="module-bottom row">
             
              <div className="col-lg-6 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-490">
                    <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">¡ Gracias por tu opinión !</h1>
                <p className="mb-4">Tu opinión y tu perspectiva nos motiva a seguir creando mejoras, para poder brindar una mejor experiencia en este proceso</p>
                <a href="/" className="btn btn--type1">Ir al inicio</a>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <FooterShared />
   </>
  );
};

export default FinalizarOpinionScreen;
