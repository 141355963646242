import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const DonateComponent: React.FC = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [formularioValido, setFormularioValido] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^\d]/g, '');

    // Asegúrate de que el valor no tenga ceros no significativos al inicio
    value = value.replace(/^0+/, '');

    // Ajusta el valor para mantener los dos últimos dígitos como decimales
    if (value) {
      // Convertir a número y luego a cadena para eliminar ceros iniciales
      const numericValue = parseInt(value, 10);
      let stringValue = numericValue.toString();

      // Agregar ceros necesarios si la longitud es menor que 3 (para tener dos decimales)
      while (stringValue.length < 3) {
        stringValue = '0' + stringValue;
      }

      // Inserta un punto decimal antes de los últimos dos dígitos
      stringValue = stringValue.slice(0, -2) + '.' + stringValue.slice(-2);
      setAmount(stringValue);
    } else {
      setAmount('');
    }
  };
  

  const verifyFields = () => {
    const amountValid = parseFloat(amount) > 0;

    const camposRequeridos = Array.from(document.querySelectorAll('.requiredField'));
    const otrosCamposValidos = camposRequeridos.every((campo) => {
      const inputElement = campo as HTMLInputElement | HTMLSelectElement;
      return inputElement.value.trim() !== '';
    });

    const termsElement = document.getElementById('terms') as HTMLInputElement;
    const termsAccepted = termsElement ? termsElement.checked : false;

    setFormularioValido(amountValid && otrosCamposValidos && termsAccepted);
  };
  

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };
 
  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Eliminar todos los caracteres no dígitos
    const formattedValue = value.match(/.{1,4}/g)?.join(' ') || ''; // Dividir en grupos de 4 y unir con espacios
    setCardNumber(formattedValue);
    verifyFields();
  };

  const handleExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea dígito
    // Asegúrate de no exceder 4 dígitos y agregar la barra después de los primeros dos dígitos
    const formattedValue = value.length <= 2 ? value : `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    setExpiryDate(formattedValue);
    verifyFields();
  };


  useEffect(() => {
    verifyFields();
  }, [amount]);

  const handleSubmit = () => {
    navigate('/donativo-gracias');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <label htmlFor="amount" className="form-label">
          Escribe el monto
        </label>
        <div className="form-input-hide">
          <p className="text-gray600">$</p>
          <input
            type="text"
            className="form-control form--amount requiredField"
            id="amount"
            value={amount}
            onChange={handleChange}
            placeholder="00.00"
          />
          <p className="text-gray600">CLP</p>
        </div>
      </div>
      <div className="form-row">
        <p className="text-icon"><i className="icon icon--card"></i> Escribe los datos de tu tarjeta</p>
      </div>
      <div className="form-row">
        <label htmlFor="address" className="form-label label--icon">
          Dirección <small className="required">*</small>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip >
                Ingresa la dirección correspondiente a tu tarjeta.
              </Tooltip>
            }
          >
            <span className="material-icons-outlined icon">help_outline</span>
          </OverlayTrigger>
        </label>
          <input
              type="text"
              className="form-control requiredField"
              id="address"
              placeholder="Escribe la dirección de la tarjeta"
              onChange={verifyFields}
            />
      </div>
      <div className="form-row">
        <div className="row">
          <div className="form-row__col-small col-lg-6 col-12">
            <label htmlFor="postal" className="form-label label--icon">
            Código Postal <small className="required">*</small>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    Ingresa el código postal correspondiente a tu tarjeta.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <input
                type="number"
                className="form-control requiredField"
                id="postal"
                placeholder="Escribe tu C.P."
                onChange={verifyFields}
              />
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="col" className="form-label label--icon">
            Colonia <small className="required">*</small>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    Ingresa la colonia correspondiente a tu tarjeta.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <input
                type="text"
                className="form-control requiredField"
                id="col"
                placeholder="Escribe tu colonia"
                onChange={verifyFields}
              />
          </div>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="card" className="form-label label--icon">
        Número de Tarjeta <small className="required">*</small>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip >
                Ingresa los 16 digitos de tu tarjeta.
              </Tooltip>
            }
          >
            <span className="material-icons-outlined icon">help_outline</span>
          </OverlayTrigger>
        </label>
          <input
              type="text"
              className="form-control requiredField"
              id="card"
              placeholder="Escribe los 16 dígitos de tu tarjeta"
              value={cardNumber}
              onChange={handleCardNumberChange}
              maxLength={19}
            />
      </div>
      <div className="form-row">
        <label htmlFor="name" className="form-label label--icon">
        Nombre <small className="required">*</small>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip >
                Ingresa el nombre de la tarjeta.
              </Tooltip>
            }
          >
            <span className="material-icons-outlined icon">help_outline</span>
          </OverlayTrigger>
        </label>
          <input
              type="text"
              className="form-control requiredField"
              id="name"
              placeholder="Escribe el nombre de la tarjeta"
              onChange={verifyFields}
            />
      </div>
      <div className="form-row">
        <div className="row">
          <div className="form-row__col-small col-lg-6 col-12">
            <label htmlFor="month" className="form-label label--icon">
            Fecha de vencimiento <small className="required">*</small>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    Ingresa la fecha de vencimiento MES/AÑO correspondiente a tu tarjeta.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <input
                type="text"
                className="form-control requiredField"
                id="month"
                placeholder="MM/AA"
                onChange={handleExpiryChange}
                value={expiryDate}
                maxLength={5}
              />
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="cvv" className="form-label label--icon">
            Código de seguridad CVV <small className="required">*</small>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    Ingresa los digitos de seguridad que estan atrás de tu tarjeta.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <input
                type="number"
                className="form-control requiredField"
                id="cvv"
                placeholder="***"
                onChange={verifyFields}
              />
          </div>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="suscription" className="form-label label--icon">
        ¿Deseas donar con cargo periódico a tu tarjeta?
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip >
                Es un cantidad que se cobraría mes con mes.
              </Tooltip>
            }
          >
            <span className="material-icons-outlined icon">help_outline</span>
          </OverlayTrigger>
        </label>
        <select
            className="form-select "
            aria-label="¿Deseas donar con cargo periódico a tu tarjeta? "
            defaultValue={'default'}
            name="suscription"
            id="suscription"
           
          >
            <option value="default" disabled>Elige una de las opciones</option>
            <option value="Si">Sí</option>
            <option value="No">No</option>
          </select>
      </div>
      <div className="form-row d-flex justify-content-center module-top40">
        <div className="form-check form-switch">
          <input className="form-check-input requiredField" type="checkbox" role="switch" id="terms" onChange={handleTermsChange}/>
          <label className="form-check-label" htmlFor="terms">Acepto <a href="/terminos-y-condiciones" className="btn--simple  btn--simple--small">Términos y condiciones</a></label>
        </div>
      </div>
      <div className="form-row  form--last module-top40">
        <button
          type="submit"
          className="btn btn--type1 btn--270"
          disabled={!formularioValido}
        >Confirmar donación</button>
      </div>
    </form>
  );
};

export default DonateComponent;
