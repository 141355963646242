import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../shared/Header/footer.shared';
import ChatboxComponent from '../../../components/Process/Chatbox/chatBox.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { IUser } from '../../../types/userInterface';

const AcompanamientoScreen: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [acompanamientoCards, setAcompanamientoCards] = useState<strapiDoc>();
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchStrapiSingle('acompanamiento-card', {
          populate: {
            '0': 'cards',
            cards: {
              populate: '*',
            },
            bullets: {
              populate: '*',
            },
          },
        });
        setAcompanamientoCards(response.data);

        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { amigasUser } = currentUser;
          setUser(amigasUser);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="mb-3 text-64">Hola, Paulina</h1>
                <p className="mb-0 text-32">
                  <strong>No. 3162432</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <h2 className="h1">Tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
            </div>
            {user.step === 12 && user.assignedTo ? (
              <ChatboxComponent
                title="Tu acompañante asignada"
                subtitle="Te presentamos a quién te estará acompañando durante todo tu proceso "
                chatUrl="/chat-acompanante"
                companionName={user.assignedTo.adminId.username}
                companionRole={user.assignedTo.adminId.principalRol}
                bullets={acompanamientoCards?.attributes?.bullets || []}
                greeting={user.assignedTo.greeting || ''}
              />
            ) : (
              <div className="module-bottom row">
                <div className="mx-auto col-md-10 col-12">
                  <article className="text-center module-bottom40">
                    <h2 className="mb-4 text-52">
                      Tu acompañante aún no ha sido asignada
                    </h2>
                    <p className="text-24">
                      Estamos trabajando para asignarte una acompañante lo más
                      pronto posible.
                    </p>
                  </article>
                </div>
              </div>
            )}
            <div className="illus-row module-bottom row">
              <div className="text-center module-bottom40 col-12">
                <h3 className="h1">
                  {acompanamientoCards?.attributes.header || ''}
                </h3>
              </div>
              {(acompanamientoCards?.attributes?.cards || []).map(
                (
                  {
                    header,
                    content,
                    image: {
                      data: { attributes: card },
                    },
                  },
                  index
                ) => (
                  <div
                    key={index}
                    className="illus-row__col col-lg-4 col-sm-6 col-12"
                  >
                    <div className="item-illus">
                      <figure>
                        <img src={card.url} alt={card.alternativeText} />
                      </figure>
                      <article>
                        <h2>{header}</h2>
                        <p>{content}</p>
                      </article>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default AcompanamientoScreen;
