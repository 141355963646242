import React from 'react';

interface StepperComponentProps {
  currentStep: number;
  totalSteps: number;
}

const StepperComponent: React.FC<StepperComponentProps> = ({ currentStep, totalSteps }) => {
  const widthPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="stepper">
      <div className="stepper__main">
        <div className="stepper__item" style={{ width: `${widthPercentage}%` }}></div>
      </div>
      <p>{currentStep}/{totalSteps}</p>
    </div>
  );
};

export default StepperComponent;
