import React from 'react';
import { Card } from '../../../types/strapiDocs';

interface ChatBoxProps {
  title: string;
  subtitle: string;
  chatUrl: string;
  companionName: string;
  companionRole: string;
  bullets: Card[];
  greeting?: string;
}

const ChatboxComponent: React.FC<ChatBoxProps> = ({
  title,
  subtitle,
  chatUrl,
  companionName,
  companionRole,
  bullets,
  greeting,
}) => {
  return (
    <>
      <div className="module-bottom row">
        <div className="col-md-10 col-12 mx-auto">
          <article className="text-center module-bottom40">
            <h2 className="text-52 mb-4">{title}</h2>
            <p className="text-24">{subtitle}</p>
          </article>
          <div className="chatbox-info">
            <div className="chatbox-info__row row">
              <div className="chatbox-info__text col-lg-6 col-12">
                <ul className="list-checker">
                  {bullets.map((bullet, index) => (
                    <li key={index}>
                      <span className="icon icon--success material-icons-outlined">
                        check_circle
                      </span>
                      <article>
                        <p>
                          <strong>{bullet.header}</strong>
                        </p>
                        <p className="text-gray600">{bullet.content}</p>
                      </article>
                    </li>
                  ))}
                </ul>
              </div>
              <aside className="chatbox-info__module col-lg-6 col-12">
                <div className="chatbox-action">
                  <div className="chatbox-action__top">
                    <div className="chatbox-action__user">
                      <figure>
                        <img
                          src="/assets/images/icons/user-pic-2.svg"
                          alt={companionName}
                        />
                      </figure>
                      <article>
                        <h4>{companionName}</h4>
                        <p className="text-20 text-gray600">{companionRole}</p>
                      </article>
                    </div>
                    <a href={chatUrl} className="btn btn--chat">
                      Escríbeme <i className="icon icon--chat"></i>
                    </a>
                  </div>
                  <div className="chatbox-action__bottom">
                    <p className="text-black">{`"${greeting}"`}</p>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatboxComponent;
