import React from 'react';

interface CheckerBannerProps {
  id: number;
  number?: string;
  text: React.ReactNode;
  value: string;
  onChange: (
    id: number,
    number: string | undefined,
    text: string,
    isChecked: boolean
  ) => void;
  checked?: boolean;
}

const CheckerBannerComponent: React.FC<CheckerBannerProps> = ({
  id,
  number,
  text,
  value,
  onChange,
  checked,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(id, number, value, event.target.checked);
  };

  return (
    <div className="form-check accordeon--checker">
      <input
        className="form-check-input"
        type="checkbox"
        id={`checkbox-${id}`}
        onChange={handleCheckboxChange}
        value={value}
        checked={checked}
      />
      <div className="form-check-input__back" />
      <label className="form-check-label" htmlFor={`checkbox-${id}`}>
        {number && <span className="accordeon-number">{number}.</span>}
        <span className="accordeon-text text-start">{text}</span>
      </label>
    </div>
  );
};

export default CheckerBannerComponent;
