import axios from 'axios';
import qs from 'qs';
import { strapiDoc, strapiDocPaginate } from '../types/strapiDocs';

const strapi = axios.create({
  baseURL: 'https://cms.conlasamigas.org/api',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});

const fetchStrapiDocs = async (route: string, params: object) => {
  const query = qs.stringify(params);
  const response = await strapi.get<strapiDocPaginate>(`${route}?${query}`);
  return response.data;
};

const fetchStrapiSingle = async (route: string, params: object) => {
  const query = qs.stringify(params);
  const response = await strapi.get<{
    data: strapiDoc;
  }>(`${route}?${query}`);
  return response.data;
};

export { fetchStrapiDocs, fetchStrapiSingle };
