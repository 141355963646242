import React, {useState, useEffect} from 'react';
import RatingComponent from '../../Form/Rating/rating.component';
import { useNavigate } from 'react-router-dom';
import DonateModalComponent from '../../Modals/Donate/donate.component';

type Ratings = {
  [category: string]: number;
};

const Step6Component: React.FC = () => {
  const navigate = useNavigate();
  const [ratings, setRatings] = useState<Ratings>({});
  const [feedback, setFeedback] = useState<string>('');
  const [feedback2, setFeedback2] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShow, setModalShow] = useState(false);

  const handleRatingChange = (category: string) => (newRating: number) => {
    setRatings(prevRatings => ({ ...prevRatings, [category]: newRating }));
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value);
  };

  const handleFeedback2Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback2(event.target.value);
  };

  // Verifica si todos los ratings y textareas tienen valor
  useEffect(() => {
    const allRatingsFilled = Object.keys(ratings).length === 6 && Object.values(ratings).every(rating => rating > 0);
    const allFeedbackFilled = feedback.trim() !== '' && feedback2.trim() !== '';
    setIsFormValid(allRatingsFilled && allFeedbackFilled);
  }, [ratings, feedback, feedback2]);

  const handleSubmit = () => {
    navigate('/opinion-completada');
  };


  return (
    <>
      <DonateModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-big-row">
            <div className="rate-row row">
              <div className="rate-col__left col-md-4">
                  <div className="card">
                    <div className="chatbox-action__user mb-4">
                      <figure>
                        <img src="/assets/images/icons/user-pic-2.svg" alt="Acompañante" />
                        
                      </figure>
                      <article>
                        <h4>Acompañante</h4>
                      </article>
                    </div>
                    <div className="form-row">
                      <label className="form-label"><strong>Acompañamiento</strong></label>
                      <RatingComponent onRating={handleRatingChange('acompanamiento')} />
                    </div>
                    <div className="form-row">
                      <label className="form-label"><strong>Comunicación</strong></label>
                      <RatingComponent onRating={handleRatingChange('comunicacion')} />
                    </div>
                    <div className="form-row">
                      <label className="form-label"><strong>Seguimiento</strong></label>
                      <RatingComponent onRating={handleRatingChange('seguimiento')} />
                    </div>
                  </div>
              </div>
              <aside className="col-md-7 offset-md-1 col-12">
                <h3 className="h2">¿Cómo fue tu experiencia?</h3>
                <p className="text-24"><strong>Comparte una retroalimentación</strong></p>
                <div className="form-row">
                <label className="form-label">Puedes contarnos lo que te gustó, te funcionó o lo que no te agradó dentro de todo el proceso.<small className="required">*</small></label>
                <textarea
                  className="form-control requiredField"
                  placeholder="Escribe tu opinión aquí"
                  id="feedback"
                  value={feedback}
                  onChange={handleFeedbackChange}
                ></textarea>
              </div>
              </aside>
            </div>
          </div>
          <div className="form-big-row not-border">
            <div className="rate-row row">
              <div className="rate-col__left col-md-4">
                  <div className="card">
                    <div className="chatbox-action__user mb-4">
                      <figure>
                        <img src="/assets/images/icons/user-pic-2.svg" alt="Tallerista" />
                        
                      </figure>
                      <article>
                        <h4>Tallerista</h4>
                      </article>
                    </div>
                    <div className="form-row">
                      <label className="form-label"><strong>Temas tratados</strong></label>
                      <RatingComponent onRating={handleRatingChange('temas')} />
                    </div>
                    <div className="form-row">
                      <label className="form-label"><strong>Horario y tiempo acordado</strong></label>
                      <RatingComponent onRating={handleRatingChange('horarios')} />
                    </div>
                    <div className="form-row">
                      <label className="form-label"><strong>Claridad de la información</strong></label>
                      <RatingComponent onRating={handleRatingChange('claridad')} />
                    </div>
                  </div>
              </div>
              <aside className="col-md-7 offset-md-1 col-12">
                <h3 className="h2">¿Cómo fue tu experiencia?</h3>
                <p className="text-24"><strong>Comparte una retroalimentación</strong></p>
                <div className="form-row">
                <label className="form-label">Puedes contarnos lo que te gustó, te funcionó o lo que no te agradó dentro de todo el proceso.<small className="required">*</small></label>
                <textarea
                  className="form-control requiredField"
                  placeholder="Escribe tu opinión aquí"
                  id="feedback2"
                  value={feedback2}
                  onChange={handleFeedback2Change}
                ></textarea>
              </div>
              </aside>
            </div>
          </div>
          <div className="form-big-row big-row--last row d-flex justify-content-center">
            <button type="button" className="btn btn--type2 btn--270" onClick={() => setModalShow(true)}>Hacer donativo</button>
            <button type="submit" className="btn btn--type1 btn--270" disabled={!isFormValid}>Subir evaluación</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step6Component;
