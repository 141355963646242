import React, { useState } from 'react';
import StopProcessComponent from '../../Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../Modals/StopConfirmModal/stopConfirmModal.component';
import { useNavigate } from 'react-router-dom';

const Step1Component: React.FC = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const handleDelete = () => {
    setModalShow(false);
    setModalShowConfirm(true);
    navigate('/');
  };
  return (
    <>
      <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="col-12">
        <h3 className="h2 text-400">Vamos a revisar tu información</h3>
        <div className="alert alert--simple-check col-md-8 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20">
            <strong>
              Recibimos tu información completa. Estamos asignándote a tu
              acompañante. Te mantendremos informada.
            </strong>
          </span>
        </div>
        <article>
          <ul className="text-20">
            <li className="mb-3">
              Tu acompañante es una mujer que está capacitada y tiene
              experiencia suficiente para guiarte de manera diaria, inclusive
              una vez que logres tu procedimiento.
            </li>
            <li>Contáctala en todo momento que lo sientas necesario.</li>
          </ul>
        </article>
      </div>
      <div className="col-lg-5 col-12">
        <div className="form-row module-top40 form--last">
          <a href="/acompanamiento" className="btn btn--type1">
            Ver acompañamiento
          </a>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            onClick={() => setModalShow(true)}
          >
            Ya no necesito este acompañamiento
          </button>
        </div>
      </div>
    </>
  );
};

export default Step1Component;
